
import { Options, Vue } from "vue-class-component";

@Options({
  name: "Curiculum Vitae",
})
export default class CuriculumVitae extends Vue {
  // ...
  created(): void {
    this.$store.commit("setCurrentPageTitle", "Curiculum Vitae");
  }
  reveal(id: string): void {
    if (!document.getElementById(id).classList.contains("hide")) {
      document.getElementById(id)?.classList.add("hide");
      document.getElementById(id)?.classList.add("hidden");
    } else {
      document.getElementById(id)?.classList.remove("hide");
      document.getElementById(id)?.classList.remove("hidden");
      if (id === "second-span") {
        document.getElementById(id)?.classList.add("clip-from-left-to-right");
      } else {
        document.getElementById(id)?.classList.add("clip-from-right-to-left");
      }
    }
  }
  goToPresentationPage(): void {
    document
      .getElementById("curiculum-vitae-div")
      ?.classList.add("fade-out-right");
    document
      .getElementById("curiculum-vitae-div")
      ?.addEventListener("animationend", () => {
        this.$router.push("/about");
        this.$store.commit("setCurrentPageTitle", "Presentation");
      });
  }
  goToCompentencesPage(): void {
    document
      .getElementById("curiculum-vitae-div")
      ?.classList.add("fade-out-left");
    document
      .getElementById("curiculum-vitae-div")
      ?.addEventListener("animationend", () => {
        this.$router.push("/skills");
        this.$store.commit("setCurrentPageTitle", "Compétences");
      });
  }
  switchOnNextStep(): void {
    document.getElementById("first-step-cv")?.classList.add("fade-out-top");
    document
      .getElementById("first-step-cv")
      ?.addEventListener("animationend", () => {
        document.getElementById("second-step-cv")?.classList.remove("hide");
        document.getElementById("first-step-cv")?.remove();
        document.getElementById("caret-down-cv")?.remove();
      });
  }
  mounted(): void {
    // listen to left and right arrow to navigate to /about and /skills
    document.addEventListener("keydown", (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        this.goToPresentationPage();
      } else if (event.key === "ArrowRight") {
        this.goToCompentencesPage();
      } else if (event.key === "ArrowDown") {
        if (
          !document.getElementById("first-step-cv")?.classList.contains("hide")
        ) {
          this.switchOnNextStep();
        }
      }
    });

    // remove clip-from-left-to-right on line-two-div
    document
      .getElementById("line-two-div-id")
      ?.classList.remove("one-line-fade-from-left");

    document
      .getElementById("first-div-id")
      .addEventListener("animationend", () => {
        document.getElementById("second-div-id")?.classList.remove("hide");
        document
          .getElementById("first-span-content-id")
          ?.classList.add("hithere");
      });
    document
      .getElementById("second-div-id")
      ?.addEventListener("animationend", () => {
        document.getElementById("third-div-id")?.classList.remove("hide");
      });
    document
      .getElementById("third-div-id")
      ?.addEventListener("animationend", () => {
        document
          .getElementById("second-span-content-id")
          ?.classList.add("hithere");
        document.getElementById("line-two-div-id")?.classList.remove("hide");
        document
          .getElementById("line-two-div-id")
          ?.classList.add("one-line-fade-from-left");
      });
    document
      .getElementById("line-two-div-id")
      ?.addEventListener("animationend", () => {
        document.getElementById("fifth-div-id")?.classList.remove("hide");
      });
    document
      .getElementById("fifth-div-id")
      .addEventListener("animationend", () => {
        document
          .getElementById("third-span-content-id")
          ?.classList.add("hithere");
        document.getElementById("caret-down-cv")?.classList.remove("hide");
        document.getElementById("caret-down-cv")?.classList.add("pulse");
      });
    document
      .getElementById("sixth-div-id")
      ?.addEventListener("animationend", () => {
        document
          .getElementById("fourth-span-content-id")
          ?.classList.add("hithere");
        document.getElementById("seventh-div-id")?.classList.remove("hide");
      });
    document
      .getElementById("seventh-div-id")
      ?.addEventListener("animationend", () => {
        document.getElementById("eighth-div-id")?.classList.remove("hide");
      });
    document
      .getElementById("eighth-div-id")
      ?.addEventListener("animationend", () => {
        document
          .getElementById("fifth-span-content-id")
          ?.classList.add("hithere");
        document.getElementById("ninth-div-id")?.classList.remove("hide");
      });
    document
      .getElementById("ninth-div-id")
      .addEventListener("animationend", () => {
        document.getElementById("tenth-div-id")?.classList.remove("hide");
      });
    document
      .getElementById("tenth-div-id")
      ?.addEventListener("animationend", () => {
        document
          .getElementById("sixth-span-content-id")
          ?.classList.add("hithere");
      });
  }
}
